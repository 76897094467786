<template>
  <div class="address">
    <NavTop navType="fun_text" textCon="收货地址">
      <template v-slot:rightText>
        <span @click="goAddress">添加</span>
      </template>
    </NavTop>
    <div class="address-list">
      <div class="address-con">
        <SwipeCell v-for="(item, idx) in arddressList" :key="idx">
          <div class="address-main" @click="setAddId(item.id)">
            <div class="address-left">
              <span class="iconfont iconaddress"></span>
            </div>
            <div class="address-text">
              <span class="address-text-title">
                {{item.name}}
                <span style="font-size:12px;color:#8C8C8C">{{item.mobile}}</span>
                <Tag color="#f2826a"  size="medium" style="margin-left:10px" v-if="item.is_default == 1 ? true:false ">默认</Tag>
                <Tag color="#f2826a"  size="medium" style="margin-left:10px" v-if="item.tag">{{item.tag}}</Tag>
              </span>
              <span class="address-info">{{item.address}}</span>
            </div>
            <span class="change-address" @click.stop="editInfo(item.id)">编辑</span>
          </div>
          <template slot="right">
            <Button class="big-btn" square type="primary" @click="setMoAddress(item.id)" text="默认" />
            <Button class="big-btn" square type="danger" @click="delAddress(item.id)" text="删除" />
          </template>
        </SwipeCell>
      </div>
    </div>

  </div>
</template>
<script>
import NavTop from "@components/public/searchTop";
import { SwipeCell, Button, Tag } from "vant";
import BetterScroll from "better-scroll";
import Address from "@api/address";
export default {
  components: {
    NavTop,
    SwipeCell,
    Button,
    Tag
  },
  data() {
    return {
      arddressList: []
    };
  },
  methods: {
    // 设置地址id
    setAddId(id) {
      console.log(this.$route.query);
      if (this.$route.query.hasOwnProperty("type")) {
        return 0;
      } else {
        this.$store.commit("setAddressId", id);
        this.$router.go(-1);
      }
      
      // let list = this.$route.query;
      // list.id = id;
      // this.$router.replace({
      //   path: "/makeorder",
      //   query: list
      // })
    },
    // 修改跳转
    editInfo(idx) {
      console.log('aaa');
      this.$router.push({
        path: "/addaddress",
        query: {
          id: idx,
          change: true
        }
      });
    },
    goAddress() {
      this.$router.push({
        path: "/addaddress",
        query: {
          change: false
        }
      });
    },
    // 设置默认地址
    async setMoAddress(idx) {
      try {
        const res = await Address.setInitAddress(idx);
        this.$toast(res.msg);
        this.$router.go(-1);
        this.$nextTick(() => {
          this.getList();
        }, 100)
      } catch(err) {
        console.log(err);
      }
    },
    // 删除地址
    async delAddress(idx) {
      try {
        const res = await Address.delAddress(idx);
        if (res.code == 0) {
          this.$toast("删除成功");
          this.$nextTick(() => {
            this.getList();
          }, 100)
        } else {
          this.$toast("删除失败");
        }
      } catch(err) {
        console.log(err);
      }
    },
    async getList() {
      try {
        const res = await Address.getAddressList();
        if (res.data.length > 0) {
          this.arddressList = res.data;
        } else {
          this.arddressList = [];
          console.log("暂无地址信息...");
        }
      } catch(err) {
        console.log(err);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      var warpper = document.querySelector('.address-list');
      this.Scroll = new BetterScroll(warpper, {
        probeType: 2,
        bounceTime: 700,
        click: true,
        pullUpLoad: {
          threshold: 10
        },
        pullDownRefresh:{
          threshold: -10
        },
        bounce: {
          top:false
        },
        useTransition: false
      });
      // 获取地址列表
      this.getList();
    })
  }
};
</script>
<style lang="scss" scoped>
.address {
  width: 100%;
  height: auto;
  overflow-y: scroll;
  background: #f4f4f4;
  padding: 50px 10px 0;
  .big-btn {
    width: 62px;
    height: 100%;
    border: none;
  }
  .big-btn:nth-child(1) {
    background: #ff8000;
  }
  .address-list {
    width: 100%;
    height: calc(100vh - 50px);
    overflow: auto;
    .address-con {
      width: 100%;
      height: auto;
      .address-main {
        width: 100%;
        height: 68px;
        display: flex;
        margin: 8px auto 0;
        display: flex;
        padding: 0 10px;
        align-items: center;
        border-radius: 6px;
        background: #ffffff;
        justify-content: space-between;
        .address-left {
          width: 30px;
          text-align: center;
          span {
            font-size: 20px;
          }
        }
        .address-text {
          width: 240px;
          height: 50px;
          display: flex;
          flex-direction: column;
          .address-text-title {
            font-size: 16px;
            color: #333333;
          }
          .address-info {
            margin-top: 4px;
          }
        }
        .change-address {
          display: block;
          padding-left: 10px;
          border-left: 1px solid #dfdfdf;
        }
      }
    }
  }


}
</style>

<style lang="scss">
.address {
  .my-search {
    height: 0;
  }
}
</style>