<template>
  <div class="my-search">
    <!--首页顶部导航-->
    <div class="search-con" v-if="navType == 'nav'" :style="sty">
      <div class="search-item">
        <span class="iconfont iconscan" @click="goBack"></span>
        <span>智联同城</span>
        <span class="iconfont iconjia" @click="showControl = !showControl"></span>
      </div>
    </div>
    <!--搜索导航-->
    <div class="search-two-con" v-if="navType == 'search'" :style="sty">
      <span class="iconfont iconhoutui" @click="goBack"></span>
      <div class="search-icon">
        <span class="iconfont iconsousuo" style="color:#8C8C8C"></span>
        <span @click="goSearch">请输入搜索的商家或商品名称</span>
      </div>
      <span class="iconfont icongouwuche2" v-if="carIcon" @click="goBuy"></span>
      <span class="iconfont icongengduo" v-if="more"></span>
    </div>
    <!--文字 + 返回-->
    <div class="order-nav" v-if="navType == 'backnav'">
      <span class="iconfont iconhoutui left" @click="goBack"></span>
      <span>{{textCon}}</span>
    </div>
    <!--文字 右侧自定义导航-->
    <div class="fun-nav" v-if="navType == 'fun_text'" :style="sty2">
      <span class="iconfont iconhoutui" @click="goBack" :style="textColor"></span>
      <span :style="textColor">{{textCon}}</span>
      <slot name="rightText"></slot>
    </div>
    <!--店铺搜索-->
    <div class="store-search" v-if="navType == 'store'">
      <span class="iconfont iconhoutui" style="color:#fff" @click="goBack"></span>
      <div class="store-main">
        <span class="iconfont iconsousuo"></span>
        <input type="text" placeholder="店内搜素">
      </div>
      <slot name="rightText"></slot>
    </div>

    <!--操作面板-->
    <div class="control-con" v-if="showControl">
      <div class="sanjiao"></div>
      <router-link :to="item.url" class="control-item" v-for="(item, idx) in ControlArray" :key="idx">
        <span :class="[item.icon, 'iconfont']"></span>
        <span>{{item.text}}</span>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    navType: {
      type: String,
    },
    textCon: {
      type: String,
      default: ""
    },
    carIcon: {
      type: Boolean,
      default: true
    },
    sty: {
      type: Object,
      default: function() {
        let Obj = {};
        Obj.background = "linear-gradient(-90deg,rgba(255, 128, 0, 1),rgba(255, 174, 0, 1))"
        return Obj;
      }
    },
    sty2: {
      type: Object,
    },
    ControlArray: {
      type: Array
    },
    more: {
      type: Boolean,
      default: true
    },
    textColor:{
      type: Object,
      default: function () {
        let obj = {};
        obj.color = "#333"
        return obj
      }
    }
  },
  data() {
    return {
      showControl: false
    }
  },
  methods: {
    goBuy() {
      this.$router.push('shopping');
    },
    goSearch() {
      this.$router.push('search');
    }
  }
};
</script>
<style lang="scss" scoped>
$height: 45px;
$width: 100%;
@mixin wd($wid, $hig) {
  width: $wid;
  height: $hig;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@mixin topPos() {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 45px;
  line-height: 45px;
  background: #ffffff;
}
.my-search {
  width: 100%;
  height: 45px;
  background: none;
  .control-con {
    width: 125px;
    height: 170px;
    position: fixed;
    top: 56px;
    right: 8px;
    color: #ffffff;
    background:rgba(0,0,0,0.6);
    border-radius: 5px;
    z-index: 500;
    .sanjiao {
      width: 0;
      height: 0;
      position: absolute;
      top: -20px;
      right: 8px;
      z-index: 500;
      border: 10px solid transparent;
      border-bottom: 10px solid rgba(0,0,0,0.6);
    }
    .control-item {
      width: 90%;
      height: 42.5px;
      box-sizing: border-box;
      display: flex;
      font-size: 14px;
      color: #ffffff;
      text-decoration: none;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      padding: 0 12px;
      border-bottom: 1px solid #ffffff;
    }
    .control-item:last-child() {
      border: none;
    }
  }
  .search-con {
    @include topPos();
    display: flex;
    flex-direction: column;
    color: #ffffff;
    z-index: 999;
    .search-item {
      @include wd(100%, 90px);
      padding: 0 10px;
      font-size: 18px;
      color: #333333;
      font-weight: 500;
      color: #ffffff;
      .iconfont {
        font-weight: 560;
        font-size: 18px;
      }
    }
  }
  .search-two-con {
    @include topPos();
    display: flex;
    padding: 0 12px;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    .iconfont {
      font-size: 20px;
    }
    .search-icon {
      width: 255px;
      height: 25px;
      display: flex;
      align-items: center;
      color: #8c8c8c;
      background: rgba(244, 244, 244, 1);
      border-radius: 5px;
      span {
        margin-left: 10px;
      }
    }
  }
  .order-nav {
    @include topPos();
    padding: 0 10px;
    text-align: center;
    font-size: 16px;
    color: #333333;
    font-weight: 500;
    .left {
      display: block;
      float: left;
    }
  }
  .fun-nav {
    @include topPos();
    display: flex;
    padding: 0 10px;
    justify-content: space-between;
    background: #ffffff;
    span:nth-child(1) {
      font-size: 16px;
      color: #333333;
    }
    span:nth-child(2) {
      font-size: 16px;
      color: #333333;
    }
    span:nth-child(3) {
      font-size: 14px;
      color: #ff8000;
    }
  }
  .store-search {
    @include topPos();
    position: absolute;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    background: transparent;
    .store-main {
      width: 280px;
      height: 100%;
      background: rgba(255,255,255,0.2);
      display: flex;
      border-radius: 3px;
      align-items: center;
      margin-left: 10px;
      padding-left: 10px;
      .iconsousuo {
        color: #ffffff;
        font-size: 22px;
      }
      input {
        background: none;
        border: none;
        height: 100%;
        color: #ffffff;
        padding-left: 14px;
        display: inline-block;
      }
      input::-webkit-input-placeholder {
        color: #ffffff;
      }
      input::-moz-input-placeholder {
        color: #ffffff;
      }
      input::-ms-input-placeholder {
        color: #ffffff;
      }
    }
  }
}

</style>