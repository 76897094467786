import { post, App_ID } from "./publicHeader";
// var Token = getToken("access_token");

class Address {
  area_id = null;
  address = null;
  name = null;
  mobile = null;
  is_default = null;
  area_name = null;
  tag = null;

  constructor( area_id, address, name, mobile, is_default, area_name, tag) {
    this.area_id = area_id;
    this.address = address;
    this.name = name;
    this.mobile = mobile;
    this.is_default = is_default;
    this.area_name = area_name;
    this.tag = tag;
  }
  // 获取行政区编码
  async getAreaList(area_id) {
    const res = await post("/shop/user.area/area_list", {
      area_id
    });
    return res;
  }

  // 获取地址列表
  async getAddressList() {
    const res = await post("/shop/user.address/getUserShipList", {
      app_id: App_ID,
    });
    return res;
  }
  // 删除收货地址
  async delAddress(id) {
    const res = await post("/shop/user.address/removeShip", {
      app_id: App_ID,
      id
    });
    return res;
  }
  // 保存地址
  async saveAddress(area_id, address, name, mobile, is_default, tag) {
    const res = await post("/shop/user.address/saveUserShip", {
      app_id: App_ID,
      area_id: parseInt(area_id),
      address,
      name,
      mobile,
      is_default,
      tag
    });
    return res;
  }
  // 设置默认收货地址
  async setInitAddress(id) {
    const res = await post("/shop/user.address/setDefaultShip", {
      app_id: App_ID,
      id
    });
    return res;
  }
  // 修改收货地址
  async editAddress(id, area_id, address, name, mobile, is_default, tag) {
    const res = await post("/shop/user.address/editShip", {
      app_id: App_ID,
      id,
      area_id: parseInt(area_id),
      address,
      name,
      mobile,
      is_default,
      tag
    });
    return res;
  }
  // 根据id获取收货地址详情
  async getAddressDet(idx) {
    const res = await post("/shop/user.address/getShipDetail", {
      app_id: App_ID,
      id: idx
    });
    const {
      area_id,
      address,
      name,
      mobile,
      is_default,
      area_name,
      tag
    } = res.data;
    return new Address(area_id, address, name, mobile, is_default, area_name, tag);
  }
  // 获取用户默认收货地址
  async getUserInitAddress() {
    try {
      const res = await post("/shop/user.address/getUserDefaultShip", {
        app_id: App_ID,
      });
      return res;
    } catch(err) {
      console.log(err);
    }
  }
}
export default new Address();
